@import "../../../../../style/typography.scss";
@import "../../../../../style/spacing.scss";

.title {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeMedium;
}

.sequenceBlock {
  margin: $spacing-m 0;
  .sectionTitle {
    margin: $spacing-s 0;
    width: max-content;
    font-weight: 500;
  }
  .sequenceTitle {
    font-weight: $fontWeightLight;
    margin-right: $spacing-xs;
    display: block;
  }
}
