@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/sizes.scss";
@import "../../../style/spacing.scss";

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 50%;
    margin-right: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: $primary;
    border-radius: $borderRadius;
    padding: $spacing-m;
    color: currentColor;
    &::placeholder {
      opacity: 1;
    }
    &:focus::placeholder {
      opacity: 1;
    }

    &:hover {
      border-color: $primaryDark;
    }
    &:focus,
    &:active,
    &:focus-within {
      outline-width: 1px;
      outline-color: $primaryDark;
    }
  }
}

.serviceTypesList {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: $spacing-l;
  margin: $spacing-xl 0;
}
