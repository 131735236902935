@import "../../../../../style/typography.scss";
@import "../../../../../style/spacing.scss";

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.title {
  font-weight: $fontWeightMedium;
  font-size: $fontSizeMedium;
}

.row {
  display: flex;
  align-items: center;

  .column {
    &.grow {
      flex: 1;
    }

    &.w250 {
      width: 250px;
    }
  }
}

.card {
  margin-bottom: $spacing-s;

  .icon {
    padding: $spacing-s;
  }

  .info {
    display: flex;

    & > div:first-child {
      padding-right: $spacing-m;
    }
  }
}
