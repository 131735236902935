@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";

.pageContent {
  display: grid;
  grid-template-columns: 24rem minmax(0, 1fr);
  gap: 32px;
  min-height: 50vh; //TBD

  .navigation {
    background: $pageSubMenu;

    a {
      color: $darkGrey;
    }

    ul {
      padding: 8px 0;
    }

    .navButton {
      padding: 4px 16px;
    }
    .active {
      color: $affordance;
    }
  }
}
