@import "../../../style/colors.scss";
@import "../../../style/mixins.scss";
@import "../../../style/spacing.scss";
@import "../../../style/typography.scss";

.page {
  margin: 0;
}

.filter {
  margin: $spacing-m 0;
}

.card {
  border-top: 1px solid $borderColorLight;
  padding: $spacing-s;
  display: grid;
  grid-template-columns: 2fr 2fr;

  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }

  .header {
    display: flex;

    .left {
      flex: 1;
    }

    .right {
      flex: 1;
      text-align: right;
    }
  }

  .status {
    display: flex;
    justify-content: flex-end;
    font-weight: $fontWeightMedium;

    &.status20 {
      color: $lightGrey;
    }
    &.status80 {
      color: $orange;
    }
    &.status90 {
      color: $greenDark;
    }
    &.status99 {
      color: $red;
    }
  }

  .text {
    display: flex;

    .label {
      font-weight: $fontWeightLight;
      margin-right: $spacing-s;
    }
  }

  .identityNumber {
    margin-left: 8.2rem;
  }
  .bold {
    font-weight: $fontWeightMedium;
  }

  .label {
    font-weight: $fontWeightLight;
    padding-right: 3px;
  }

  @include medium-screen {
    grid-template-columns: 1fr;
    .left {
      justify-content: unset !important;
    }
  }
}

.searchGroup {
  display: flex;

  @include small-screen {
    display: block;
  }

  & > * {
    margin: 0 $spacing-m;
    flex: 1;

    @include small-screen {
      margin: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
