@import "../../../../style/colors.scss";
@import "../../../../style/spacing.scss";
@import "../../../../style/typography.scss";
@import "../../../../style/sizes.scss";
$inputHeight: 46px;

.layout {
  display: grid;
  grid-template-columns: 2fr 1fr;

  .title {
    @extend %sectionTitle;
  }
  .formDetails {
    border: 1px solid $borderColorLight;
    padding: $spacing-m;
    margin-right: $spacing-m;
  }
  .input {
    max-height: $inputHeight;
    min-height: auto !important;
    padding: 1rem !important;
    width: 30rem;
  }
  .description {
    margin-left: $spacing-s;

    ul,
    ol {
      list-style: unset;
    }
  }
}
